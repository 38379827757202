import React, { useState } from 'react'
import {
  Box,
  Container,
  ContainerProps,
  Flex,
  HStack,
  IconButton,
  SimpleGrid,
  SimpleGridProps,
  useBreakpointValue
} from '@chakra-ui/react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from '@icons/index'
import { useResponsive } from '@common/hooks'

interface ColumnConfig {
  xl?: number
  lg?: number
  md?: number
  sm?: number
  base?: number
}

const PaginationLayout = ({
  data,
  itemsPerPage,
  renderItem,
  skipPagination,
  children,
  totalPages,
  gridProps,
  containerProps,
  isLoading,
  ...rest
}: {
  data: any
  itemsPerPage: ColumnConfig
  renderItem: React.ReactNode
  children: React.ReactNode
  gridProps?: SimpleGridProps
  containerProps?: ContainerProps
  skipPagination?: boolean
  totalPages?: number
  isLoading?: boolean
} & any) => {
  const { xlLargeMax, smMax, xlMin } = useResponsive()

  const [currentPage, setCurrentPage] = useState(1)
  const items = data

  const currentItemsPerPage = useBreakpointValue(itemsPerPage)

  const indexOfLastItem = currentPage * currentItemsPerPage
  const indexOfFirstItem = indexOfLastItem - currentItemsPerPage
  const currentItems = skipPagination
    ? items
    : items.slice(indexOfFirstItem, indexOfLastItem)

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const totalPs = totalPages
    ? Math.ceil(totalPages / currentItemsPerPage)
    : Math.ceil(items.length / currentItemsPerPage)

  return (
    <Container
      px={{ base: 4, lg: 5, xl: 8, '2xl': 0 }}
      {...containerProps}
      pointerEvents={isLoading ? 'none' : 'initial'}
    >
      {items != null && items.length > 0 && (
        <Box {...rest}>
          <Box position="relative">
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
            <SimpleGrid
              columns={currentItemsPerPage}
              spacingX={{ base: 5, lg: '40px' }}
              spacingY={smMax ? 12 : 16}
              justifyContent={'center'}
              {...gridProps}
            >
              {currentItems.map(renderItem)}
            </SimpleGrid>

            {!skipPagination ? (
              <>
                <IconButton
                  icon={
                    <MdKeyboardArrowLeft
                      width={xlMin ? 18 : undefined}
                      height={xlMin ? 33 : undefined}
                      color={xlLargeMax ? 'white' : 'black'}
                    />
                  }
                  aria-label="Previous Page"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  visibility={currentPage === 1 ? 'hidden' : 'visible'}
                  bg={xlLargeMax ? 'blackButtonSwitch' : 'white'}
                  color={xlLargeMax ? 'white' : 'black'}
                  transform={
                    xlLargeMax
                      ? 'translateY(-50%) translateX(10%)'
                      : 'translateY(-50%) translateX(-200%)'
                  }
                  _hover={{ bg: xlLargeMax ? 'blackButtonSwitch' : 'white' }}
                  position="absolute"
                  top={'45%'}
                  left={0}
                />
                <IconButton
                  icon={
                    <MdKeyboardArrowRight
                      width={xlMin ? 18 : undefined}
                      height={xlMin ? 33 : undefined}
                      color={xlLargeMax ? 'white' : 'black'}
                    />
                  }
                  aria-label="Next Page"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPs}
                  visibility={currentPage === totalPs ? 'hidden' : 'visible'}
                  bg={xlLargeMax ? 'blackButtonSwitch' : 'white'}
                  color={xlLargeMax ? 'white' : 'black'}
                  _hover={{ bg: xlLargeMax ? 'blackButtonSwitch' : 'white' }}
                  transform={
                    xlLargeMax
                      ? 'translateY(-50%) translateX(-10%)'
                      : 'translateY(-50%) translateX(200%)'
                  }
                  position="absolute"
                  top={'45%'}
                  right={0}
                />
              </>
            ) : null}
          </Box>

          {!skipPagination && totalPs > 1 && currentItemsPerPage !== 1 && (
            <Flex justify="center" mt={4} align="center">
              <HStack
                spacing={2}
                mt={{
                  lg: 8,
                  base: 4
                }}
              >
                {[...Array(totalPs)].map((_, index) => (
                  <Box
                    key={index}
                    w={currentPage === index + 1 ? 3.5 : 2.5}
                    h={currentPage === index + 1 ? 3.5 : 2.5}
                    bg={currentPage === index + 1 ? 'white' : 'bgBold'}
                    border={
                      currentPage === index + 1 ? '2px solid' : 'transparent'
                    }
                    borderColor={currentPage === index + 1 ? 'bgBold' : 'white'}
                    borderRadius="50%"
                    cursor="pointer"
                    transition="ease-inb"
                    onClick={() => {
                      handlePageChange(index + 1)
                    }}
                  />
                ))}
              </HStack>
            </Flex>
          )}

          {children}
        </Box>
      )}
    </Container>
  )
}

export default PaginationLayout
