import { Skeleton, Stack } from '@chakra-ui/react'
import React from 'react'

const SkeletonProductCardItem = () => {
  return (
    <Stack gap={1} h={520}>
      <Skeleton
        w={330}
        h={400}
        startColor={'bgSkeleton'}
        endColor={'bgSkeleton'}
      />
      <Skeleton
        w={200}
        h={5}
        startColor={'bgSkeleton'}
        endColor={'bgSkeleton'}
      />
      <Skeleton
        w={100}
        h={5}
        startColor={'bgSkeleton'}
        endColor={'bgSkeleton'}
      />
    </Stack>
  )
}

export default SkeletonProductCardItem
