import { Box, Container } from '@chakra-ui/react'
import React from 'react'

interface PropsType {
  children: React.ReactNode
  mt?: number | string
}

const BodySubLayout: React.FC<PropsType> = ({ children }) => {
  return (
    <Box
      py={{
        md: 24,
        sm: 20,
        base: 16
      }}
    >
      <Container position="relative">{children}</Container>
    </Box>
  )
}

export default BodySubLayout
