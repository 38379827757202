import React, { Fragment } from 'react'
import { PRODUCT_LIMIT } from '@constants/product.constant'
import SkeletonProductCardItem from '@components/skeleton/skeleton-product-card-item'

const ListProductLoading = () => {
  const displayLoadingList = () => {
    const arr = Array.from(
      { length: PRODUCT_LIMIT },
      (v: number, k: number) => k + 1
    )
    return arr.map((v, k) => {
      return <SkeletonProductCardItem key={k} />
    })
  }

  return <Fragment>{displayLoadingList()}</Fragment>
}

export default ListProductLoading
