import { StoreGetCollectionsParams } from '@medusajs/medusa'
import { medusaClient } from '@lib/config'

export const listCategories = (
  query?: any,
  customHeaders?: Record<string, any>
) => medusaClient.productCategories.list(query, customHeaders)

export const listCollections = (
  query?: StoreGetCollectionsParams,
  customHeaders?: Record<string, any>
) => medusaClient.collections.list(query, customHeaders)
