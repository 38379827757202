import { useEffect, useMemo, useState } from 'react'
import { SHOP_PRODUCT_LIMIT } from '@constants/product.constant'
import {
  DataLayoutProduct,
  useFeaturedProductsQuery
} from '@lib/hooks/use-layout-data'
import { useRouter } from 'next/router'
import { CollectionData, ProductPreviewType } from 'types/global'
import { listCategories } from '@lib/util/category-handler'
import { listBrands } from '@lib/util/brand-handler'
import { useQuery } from '@tanstack/react-query'
import { FilterQueryParams, SortFilter } from '@constants/filter.constant'
import RouterUrl from '@constants/router.constant'
import { useCollections } from 'medusa-react'
import { useResponsive } from './useResponsive'

export interface FilterPropsType {
  type_id: string[]
  category_id: string[]
  collection_id: string[]
  order?: string
  q?: string
  allProductsOnePage: boolean | null
  orderPrice?: string
  offset?: number
  limit?: number
}

export interface UseFilterProductsReturn {
  dataFilter?: DataLayoutProduct
  listItems: ProductPreviewType[]
  isLoading: boolean
  currentPage: number
  setCurrentPage: (input: any) => void
  filter: FilterPropsType
  setFilter: (filter: FilterPropsType) => void
  limit: number
  setLimit: (input: number) => void
  listItemsViewMore?: ProductPreviewType[]
}

const useFilterProducts = (): UseFilterProductsReturn => {
  const [filter, setFilter] = useState<FilterPropsType>({
    type_id: [],
    category_id: [],
    collection_id: [],
    order: undefined,
    orderPrice: undefined,
    allProductsOnePage: null
  })

  const [currentPage, setCurrentPage] = useState(1)

  const [limit, setLimit] = useState(SHOP_PRODUCT_LIMIT)

  const router = useRouter()

  const { smMax } = useResponsive()

  const { data } = useQuery(['categories'], () => listCategories()) as any
  const { data: brandsList } = useQuery(['brands'], () => listBrands()) as any
  const { collections } = useCollections({ limit: 999999 })

  const addCategoriesParam = (categories: string) => {
    const category_id: string[] = []

    if (data?.product_categories != null) {
      const cate_handles = categories
        .split(',')
        .map((c: string) => c.toLowerCase())

      for (let i = 0; i < data?.product_categories?.length; i++) {
        const cate_handle = data?.product_categories[i]?.handle?.toLowerCase()
        if (cate_handles.includes(cate_handle)) {
          category_id.push(data?.product_categories[i]?.id)
        }
      }
    }

    return category_id
  }

  const addBrandsParam = (brands: string) => {
    if (!brandsList?.data?.length) {
      return []
    }

    // Split brands string query to array brand handle and map to lower case
    const splitBrandHandles = brands
      .split(',')
      .map((b: string) => b.toLowerCase())

    const collectionIds = brandsList.data
      .filter(({ handle }: { handle: string }) =>
        splitBrandHandles.includes(handle?.toLowerCase())
      )
      .map(
        ({ collections }: { collections: CollectionData[] }) =>
          collections.find(({ metadata }) => metadata?.isBrand === true)?.id ||
          ''
      )
      .filter((id: string) => id !== '') // Filter out empty strings if no matching collection found

    return collectionIds
  }

  const addCollectionsParam = (collects: string) => {
    const collection_id: string[] = []

    if (collections != null) {
      const collect_handles = collects
        .split(',')
        .map((c: string) => c.toLowerCase())

      for (let i = 0; i < collections.length; i++) {
        const collect_handle = collections[i]?.handle?.toLowerCase()
        if (collect_handles.includes(collect_handle)) {
          collection_id.push(collections[i]?.id)
        }
      }
    }

    return collection_id
  }

  const addSortParam = (sort: string) => {
    let order: string | undefined = undefined
    let orderPrice: string | undefined = undefined

    const handle_sort = (sort as string).toLowerCase()
    const findSort = SortFilter.find(
      (s) => s.handle.toLowerCase() === handle_sort
    )

    if (findSort != null) {
      if (['price', '-price'].includes(findSort.value)) {
        orderPrice = findSort.value
      } else {
        order = findSort.value
      }
    }

    return { order, orderPrice }
  }

  const addPageParam = (page: string) => {
    const pageNumber = parseInt(page)

    if (!Number.isNaN(pageNumber)) {
      return pageNumber <= 1 ? 1 : pageNumber
    }

    return 1
  }

  useEffect(() => {
    const categories = router?.query[FilterQueryParams.CATEGORIES]
    const brands = router?.query[FilterQueryParams.BRANDS]
    const sort = router?.query[FilterQueryParams.SORT]
    const page = router?.query[FilterQueryParams.PAGE]
    const search = router?.query[FilterQueryParams.SEARCH]
    const all = router?.query[FilterQueryParams.ALL]
    const collects = router?.query[FilterQueryParams.COLLECTIONS]

    let collection_id: string[] = []
    let category_id: string[] = []
    let sort_: any = {
      order: undefined,
      priceOrder: undefined
    }
    let q: string | undefined = undefined
    let allProductsOnePage: boolean | null = null

    if (
      router?.pathname === RouterUrl.SHOP.ROOT ||
      router.pathname === RouterUrl.FLASH_SALE.ROOT
    ) {
      if (
        collects != null ||
        categories != null ||
        brands != null ||
        sort != null ||
        page != null ||
        all != null
      ) {
        if (typeof collects === 'string') {
          collection_id = addCollectionsParam(collects)
        }

        if (typeof categories === 'string') {
          category_id = addCategoriesParam(categories)
        }

        if (typeof brands === 'string') {
          collection_id = [...collection_id, ...addBrandsParam(brands)]
        }

        if (typeof sort === 'string') {
          sort_ = addSortParam(sort)
        }

        if (typeof page === 'string') {
          setCurrentPage(addPageParam(page))
        }

        if (typeof search === 'string') {
          q = search
        }

        if (typeof all === 'string') {
          allProductsOnePage = true
        }

        setFilter((pre: any) => ({
          ...pre,
          category_id,
          collection_id,
          q,
          allProductsOnePage,
          order: sort_.order,
          orderPrice: sort_.orderPrice
        }))
      } else {
        setFilter((pre: any) => ({
          ...pre,
          category_id: [],
          collection_id: [],
          order: undefined,
          priceOrder: undefined,
          allProductsOnePage
        }))
      }
    }

    if (router?.pathname === RouterUrl.DEALS.ROOT) {
      const deals = data?.product_categories?.find(
        (pc: any) => pc.handle.toLowerCase() === 'deals'
      )

      if (deals != null) {
        category_id = [deals.id]

        if (brands != null || sort != null || page != null || all != null) {
          if (typeof brands === 'string') {
            collection_id = addBrandsParam(brands)
          }

          if (typeof sort === 'string') {
            sort_ = addSortParam(sort)
          }

          if (typeof page === 'string') {
            setCurrentPage(addPageParam(page))
          }

          if (typeof search === 'string') {
            q = search
          }

          if (typeof all === 'string') {
            allProductsOnePage = true
          }

          setFilter((pre: any) => ({
            ...pre,
            category_id,
            collection_id,
            q,
            order: sort_.order,
            orderPrice: sort_.orderPrice,
            allProductsOnePage
          }))
        } else {
          setFilter((pre: any) => ({
            ...pre,
            category_id,
            collection_id: [],
            order: undefined,
            priceOrder: undefined,
            allProductsOnePage
          }))
        }
      }
    }

    if (
      router?.pathname === `${RouterUrl.BRAND.ROOT}/[handle]` ||
      router?.pathname === `${RouterUrl.READ.ROOT}/[handle]`
    ) {
      if (collections != null || all != null) {
        if (typeof collects === 'string') {
          collection_id = addCollectionsParam(collects)
        }

        if (typeof all === 'string') {
          allProductsOnePage = true
        }

        setFilter((pre: any) => ({
          ...pre,
          collection_id,
          allProductsOnePage
        }))
      }
    }
  }, [router?.query, data, brandsList, collections])

  const { data: dataFilter, isLoading } = useFeaturedProductsQuery(
    {
      ...filter,
      offset: smMax
        ? 0
        : filter.allProductsOnePage
          ? 0
          : (currentPage - 1) * limit,
      limit: smMax ? undefined : filter.allProductsOnePage ? undefined : limit,
      q: router.query.q as string
    },
    smMax
  )

  useEffect(() => {
    if (router.query.cat) {
      setFilter((pre: any) => {
        return { ...pre, category_id: [...pre.category_id, router.query.cat] }
      })
    }
  }, [router.query.cat])

  const listItems = useMemo(() => {
    return dataFilter?.products ?? []
  }, [currentPage, dataFilter, filter])

  const listItemsViewMore = useMemo(() => {
    const start = 0
    const end = (currentPage - 1) * limit + limit

    const products = dataFilter?.products ?? []

    const productsPagi = products.slice(start, end)

    return filter.allProductsOnePage ? products : productsPagi
  }, [currentPage, dataFilter, filter])

  return {
    dataFilter,
    listItems,
    isLoading,
    currentPage,
    setCurrentPage,
    filter,
    setFilter,
    limit,
    setLimit,
    listItemsViewMore
  }
}

export default useFilterProducts
