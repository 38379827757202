import React from 'react'
import { Box, Button, Text } from '@chakra-ui/react'
import Link from 'next/dist/client/link'

export interface LinkTextUnderlinePropsType {
  text: string
  color: string
  underlineColor?: string
  href?: string
  textStyle?: string
  target?: string
  zIndex?: number
  onClick?: () => void
}

const LinkTextUnderline = ({
  text,
  color,
  href,
  underlineColor,
  textStyle,
  target,
  onClick,
  textProps,
  zIndex
}: LinkTextUnderlinePropsType & any) => {
  if (!href)
    return (
      <Button
        variant="unstyled"
        onClick={onClick}
        textAlign="left"
        zIndex={zIndex}
        height="auto"
      >
        <Text
          color={color}
          borderBottom={'2px solid'}
          textStyle={textStyle ?? 'linkTextUnderline'}
          borderBottomColor={underlineColor || color}
          display={'inline-block'}
          py={1}
          {...textProps}
        >
          {text}
        </Text>
      </Button>
    )

  return (
    <Box zIndex={zIndex}>
      <Link href={href} target={target}>
        <Text
          color={color}
          borderBottom={'2px solid'}
          textStyle={textStyle ?? 'linkTextUnderline'}
          borderBottomColor={underlineColor || color}
          display={'inline'}
          py={2}
          {...textProps}
        >
          {text}
        </Text>
      </Link>
    </Box>
  )
}

export default LinkTextUnderline
