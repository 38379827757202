import React from 'react'
import { ITEMS_PER_PAGE, PRODUCT_LIMIT } from '@constants/product.constant'
import { useFeaturedProductsQuery } from '@lib/hooks/use-layout-data'
import PaginationLayout from '@layouts/pagination-layout'
import ListProductLoading from './list-product-loading'
import ProductCardItem from './product-card-item'
import LinkTextUnderline from '@components/link-text-underline'
import { Box, Container, SimpleGrid } from '@chakra-ui/react'
import { useResponsive } from '@common/hooks'
import useFilterQueryParams from '@common/hooks/use-filter-query-param'
import useFilterProducts from '@common/hooks/use-filter-product'
import { FilterQueryParams } from '@constants/filter.constant'

interface PropsType {
  collections: string[]
  totalProducts: number
  data?: any
  bg?: string
  color?: string
}

const ListProductByCollection = ({
  collections,
  data,
  bg,
  color
}: PropsType) => {
  const { filter } = useFilterProducts()
  const { addAll, removeQuery } = useFilterQueryParams()

  const { data: dataLayout, isLoading } = useFeaturedProductsQuery({
    collection_id: collections,
    dataChange: data,
    limit: 99999
  })

  const { smMax } = useResponsive()

  if (isLoading)
    return (
      <Container>
        <SimpleGrid
          columns={PRODUCT_LIMIT}
          spacingX={{ base: 5, lg: '40px' }}
          spacingY={smMax ? 12 : 16}
          justifyContent={'center'}
        >
          <ListProductLoading />
        </SimpleGrid>
      </Container>
    )

  if (!isLoading && (dataLayout == null || dataLayout.products.length === 0))
    return null

  return (
    <PaginationLayout
      data={dataLayout?.products ?? []}
      itemsPerPage={ITEMS_PER_PAGE}
      skipPagination={!!filter?.allProductsOnePage}
      renderItem={(item: any, index: number) => {
        return <ProductCardItem key={index} item={item} bg={bg} color={color} />
      }}
    >
      <Box
        textAlign="center"
        mt={{
          lg: 8,
          base: 4
        }}
      >
        <LinkTextUnderline
          text={filter?.allProductsOnePage ? 'View less' : 'View more'}
          onClick={() => {
            if (!!filter?.allProductsOnePage === false) {
              addAll('true')
            } else {
              removeQuery(FilterQueryParams.ALL)
            }
          }}
          color="black"
        />
      </Box>
    </PaginationLayout>
  )
}

export default ListProductByCollection
